import { IResourceComponentsProps, useCreate } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Col, Form, Input, Row, Select, Switch } from "antd";
import {IGroup} from 'interfaces';
import React from "react";


export const GroupCreate: React.FC<IResourceComponentsProps> = () => {

    const {formProps, saveButtonProps} = useForm<IGroup>({

    });

    const save = useCreate<IGroup>();

    return (
            <Create saveButtonProps={saveButtonProps}>
                <Form
                    {...formProps}
                    layout="vertical"
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label="Description"
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Create>
    );
};
