import {HttpError, IResourceComponentsProps, useCustom, useExport, useList, useUpdate} from '@refinedev/core';

import {
    CreateButton,
    DeleteButton,
    EditButton, ExportButton, ImportButton,
    List, SaveButton, TagField, useImport, useSelect, useTable
} from '@refinedev/antd';
import {Button, Col, Form, Input, Row, Select, Space, Switch, Table, Typography} from 'antd';
import React, {useState} from 'react';
import ResizeObserver from 'rc-resize-observer';
import './list.css';
import {IContact} from '../../interfaces';
const {Title} = Typography;
export const ContactList: React.FC<IResourceComponentsProps> = () => {

    const [tableWidth, setTableWidth] = useState<any>('');

    const {tableQueryResult,tableProps, searchFormProps} = useTable<IContact, HttpError>({
        resource: 'contacts',
        pagination: {
          mode: 'server',
        },
        meta: {
            populate: 'group'
        },
        queryOptions: {
            enabled: true
        },
        onSearch: (values: any) => {
            console.log(values);
            return [
                {
                    operator: "or",
                    value: [
                        {
                            field: "name",
                            operator: "contains",
                            value: values.description
                        },
                        {
                            field: "surname",
                            operator: "contains",
                            value: values.description
                        },
                        {
                            field: "cellular",
                            operator: "contains",
                            value: values.description
                        },
                        {
                            field: "group][description]",
                            operator: "contains",
                            value: values.description
                        },
                    ]
                },
            ];
        },
    });

    const {triggerExport, isLoading: exportLoading} = useExport<IContact>();
    const importProps = useImport<IContact>();

    return (
        <List
            title={'Contatti'}
            headerProps={{
                extra: [
                    <>
                        <Form {...searchFormProps} layout="inline">
                            <Form.Item name="description">
                                <Input placeholder="Search..." allowClear={true}/>
                            </Form.Item>
                            <Button onClick={searchFormProps.form?.submit} type={'primary'} style={{backgroundColor: 'yellowgreen'}}>Cerca</Button>
                        </Form>
                        <CreateButton/>,
                        <ImportButton {...importProps} />
                        <ExportButton
                            onClick={triggerExport}
                            loading={exportLoading}
                        />
                    </>
                ],
            }}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <ResizeObserver
                        onResize={({ width }) => {
                            setTableWidth(width);
                        }}
                    >
                    <Table

                        loading={tableQueryResult.data?.data.length === 0}
                        size={'small'}
                        sticky={true}
                        scroll={{y: window.innerHeight - 270, x: 600}}
                        {...tableProps}

                    >
                        <Table.Column dataIndex="id" title="ID" />
                        <Table.Column dataIndex="name" title="Nome" />
                        <Table.Column dataIndex="surname" title="Cognome" />
                        <Table.Column dataIndex="cellular" title="Telefono" />
                        <Table.Column dataIndex="group" title="Gruppo" render={(value) => (
                            <TagField value={value ? value.description : ''} color={'yellow'}/>
                        )} />
                        <Table.Column<{ id: string }>
                            title={'Actions'}
                            dataIndex="actions"
                            key="actions"
                            render={(_, record) => (
                                <Space>
                                    <EditButton
                                        hideText
                                        size="small"
                                        recordItemId={record.id}
                                    />
                                    <DeleteButton
                                        mutationMode={'undoable'}
                                        hideText
                                        size="small"
                                        recordItemId={record.id}
                                    />
                                </Space>
                            )}
                        />
                    </Table>
                    </ResizeObserver>
                </Col>
            </Row>
        </List>
    );
};
