import { MeOptions } from "@refinedev/strapi-v4/src/helpers/auth";
import {axiosInstance} from '../authProvider';
import {API_URL, TOKEN_KEY} from '../constants';
import jwt_decode from 'jwt-decode';

export const UtilityHelper = (apiUrl: string) => ({
    permission: async (options?: MeOptions) => {
        if (localStorage.getItem(TOKEN_KEY)) {
            const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || '');
            return (decoded && decoded.group?.isAdmin) ? 'admin' : decoded.group?.isUser ? 'user' : decoded.group?.isOperator ? 'operator' : 'user'
        } else {
            return 'user';
        }

    },
    userDevice: () => {
        if (localStorage.getItem(TOKEN_KEY)) {
            const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || '');
            return (decoded && decoded.device) ? decoded.device : []
        } else {
            return [];
        }

    },
    seller: async (options?: MeOptions) => {
        if (localStorage.getItem(TOKEN_KEY)) {
            const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || '');
            return (decoded && decoded.seller) ? decoded.seller : []
        } else {
            return [];
        }

    },
    setSocketId: async (socketId?: string) => {
        if (localStorage.getItem(TOKEN_KEY)) {
            const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || '');
            await axiosInstance.put(API_URL + '/api/users/' + decoded.id, {
                socket: socketId
            });
            console.log("Update User Socket: ", socketId);
            return true;
        }
    },
});
