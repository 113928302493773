import {IResourceComponentsProps, useExport, useList, useTranslate, useUpdate} from '@refinedev/core';

import {
    BooleanField,
    CreateButton, DateField,
    DeleteButton,
    EditButton,
    ExportButton,
    List, TagField, useModal,
    useTable,
} from '@refinedev/antd';
import * as Icons from "@ant-design/icons";

import {Button, message, Modal, Space, Switch, Table, Tooltip} from 'antd';
import React, {useEffect, useState} from 'react';
import {IContact, IMessage} from '../../interfaces';
import {socket} from '../../contexts/socket';

export const MessageList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {tableProps, tableQueryResult} = useTable<IMessage>({
        resource: "messages",
        syncWithLocation: false,
        meta: {populate: '*'},
        sorters: {
            mode: 'server',
            permanent: [
                {
                    field: 'createdAt',
                    order: 'desc'
                }
            ]
        },
    });

    const [groupId, setGroupId] = useState<any>();

    const {data: getContact} = useList<IContact>({
        resource: 'contacts',
        meta: {
            populate: '*'
        },
    });

    const [messageId, setMessageId] = useState<any>();
    const [load, setLoad] = useState(false);
    const {tableProps: logMessageProps, tableQueryResult: logMessageResult} = useTable<any>({
        resource: 'log-messages',
        syncWithLocation: false,
        meta: {
            populate: 'message, contact, contact.group'
        },
        queryOptions: {
            enabled: false
        },
        pagination: {
            mode: 'server',
            pageSize: 999999
        },
        sorters: {
            mode: 'server',
            permanent: [
                {
                    field: 'createdAt',
                    order: 'desc'
                }
            ]
        },
        filters:
            {
                permanent: [
                    {
                        field: 'message][id]',
                        operator: 'eq',
                        value: messageId
                    }
                ]
            }
    });

    const {triggerExport, isLoading: exportLoading} = useExport<IMessage>();

    useEffect(() => {
        socket.on('updateMessage', async (data: any, error: any) => {
            console.log(data);
            tableQueryResult.refetch().then(res => {

            });

        });

        return () => {
            socket.off('updateMessage');
        };

    }, [])

    const getLogMessage = (id: any) => {
        setMessageId(id);
        setLoad(true);
        setTimeout(() => {
            logMessageResult.refetch().then( (res: any) => {
                // console.log(res);
                setLoad(false);
                show();
            })
        }, 1000)

    }
    const updateM = useUpdate<IMessage>();
    const stopSend = (e: any, record: any) => {
            // console.log(e, record);
            updateM.mutateAsync({
                successNotification: false,
                resource: 'messages',
                id: record.id,
                values: {
                    isStopped: e
                }
            }).then(res => {
                message.info((e ? 'Invio Fermato Correttamente!' : 'Invio Ripristinato Correttamente'));
            })
    }

    const { show, close, modalProps } = useModal();

    return (
            <List
                title={'Messaggi'}
                headerProps={{
                    extra: [
                        <CreateButton/>,
                        <ExportButton
                            onClick={triggerExport}
                            loading={exportLoading}
                        />
                    ],
                }}
            >
                <Table size="small" {...tableProps} rowKey="id"
                       onRow={(record) => {
                           return {
                               onClick: () => {
                                   // console.log("group", record);
                               },
                           };
                       }}
                       sticky={false}
                       pagination={{
                           ...tableProps.pagination,
                           showSizeChanger: true,
                       }}>
                    {/*<Table.Column dataIndex="id" key="id" title="ID"/>*/}
                    <Table.Column
                        dataIndex='id'
                        key='id'
                        title='ID'
                    />
                    <Table.Column
                        dataIndex="fromSession"
                        key="fromSession"
                        title={'Mittente'}
                    />
                    <Table.Column
                        dataIndex="contact"
                        key="contact"
                        title={'Destinatario'}
                        render={(value, record: any) => (
                            (value ? value.name : record.group.description)
                        )}
                    />
                    <Table.Column
                        dataIndex="messaggiDaInviare"
                        key="messaggiDaInviare"
                        title={'Totale Da Inviare'}
                        render={(value, record) => {
                                return (<TagField value={value ? value : 1} color={'red-inverse'}/>)
                        }}
                    />
                    <Table.Column
                        dataIndex="count"
                        key="count"
                        title={'Totale Inviati'}
                        render={(value, record) => {
                                return (<TagField value={value} color={'green-inverse'}/>)
                        }}
                    />
                    <Table.Column
                        dataIndex="status"
                        key="status"
                        title={'Stato'}
                    />
                    <Table.Column
                        dataIndex="createdAt"
                        key="createdAt"
                        title={'Data Creazione'}
                        render={(value) => (
                            <DateField value={value} format={'DD-MM-YYYY - HH:mm:ss'} />
                        )}
                    />
                    <Table.Column
                        dataIndex="dataInizio"
                        key="dataInizio"
                        title={'Data Inizio Invio'}
                        render={(value) => (
                            <DateField value={value} format={'DD-MM-YYYY - HH:mm:ss'} />
                        )}
                    />
                    <Table.Column
                        dataIndex="dataFineInvio"
                        key="dataFineInvio"
                        title={'Data Fine Invio'}
                        render={(value) => (
                            <DateField value={value} format={'DD-MM-YYYY - HH:mm:ss'} />
                        )}
                    />
                    <Table.Column
                        dataIndex="isStopped"
                        key="isStopped"
                        title={'Ferma Invio'}
                        render={(value, record) => (
                            <Switch defaultChecked={value} onChange={(e) => stopSend(e, record)}/>
                        )}
                    />
                    <Table.Column<{ id: string }>
                        title={translate('pages.users.action', 'Action')}
                        dataIndex="actions"
                        key="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                                <Button size={'small'} icon={<Icons.SearchOutlined/>} key={record.id} loading={load && (messageId === record.id)} onClick={() => getLogMessage(record.id)} />
                                <DeleteButton
                                    mutationMode={'undoable'}
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                            </Space>
                        )}
                    />

                </Table>
                <Modal {...modalProps} footer={false} width={1000}>
                    <Table {...logMessageProps} size={'small'} sticky={false} pagination={false} scroll={{y: 300}}>
                        <Table.Column
                            dataIndex='description'
                            key='description'
                            title='Descrizione'
                        />
                        <Table.Column
                            dataIndex="dataInvio"
                            key="dataInvio"
                            title={'Data Invio'}
                            render={(value) => (
                                <DateField value={value} format={'DD-MM-YYYY - HH:mm:ss'} />
                            )}
                        />
                        <Table.Column
                            dataIndex="contact"
                            key="contact"
                            title={'Destinatario'}
                            render={(value) => (
                                <TagField value={value ? value.name : ''} color={'yellow'}/>
                            )}
                        />
                        <Table.Column
                            dataIndex="contact"
                            key="contact"
                            title={'Telefono'}
                            render={(value) => (
                                <TagField value={value ? value.cellular : ''} color={'geekblue'}/>
                            )}
                        />
                        <Table.Column
                            dataIndex="isSend"
                            key="isSend"
                            title={'Inviato'}
                            render={(value, record) => (
                                <TagField value={value ? 'SI' : 'NO'} color={value ? 'green' : 'red'}/>
                            )}
                        />
                        <Table.Column
                            dataIndex="dettaglio"
                            key="dettaglio"
                            title={'Dettaglio'}
                            ellipsis={ {showTitle: false}}
                            render={(value, record) => (
                                <Tooltip placement="topLeft" title={value}>
                                    {value}
                                </Tooltip>
                            )}
                        />
                    </Table>
                </Modal>

            </List>
    );
};
