import { IResourceComponentsProps, useCreate } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Col, Form, Input, Row, Select, Switch } from "antd";
import {IContact, IGroup} from 'interfaces';
import React from "react";


export const ContactCreate: React.FC<IResourceComponentsProps> = () => {

    const {formProps, saveButtonProps} = useForm<IContact>({

    });

    const save = useCreate<IGroup>();

    const {selectProps} = useSelect<IGroup>({
        resource: 'groups',
        optionLabel: 'description',
        optionValue: 'id'
    })

    return (
            <Create saveButtonProps={saveButtonProps}>
                <Form
                    {...formProps}
                    layout="vertical"
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Nome"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Cognome"
                                name="surname"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Telefono"
                                name="cellular"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Gruppo"
                                name="group"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select {...selectProps} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Create>
    );
};
