import {IResourceComponentsProps, useCreate} from '@refinedev/core';
import {Create, useForm, useModal} from '@refinedev/antd';
import {Button, Col, Form, Image, Input, Modal, Row} from 'antd';
import {ISession} from 'interfaces';
import React, {useEffect, useState} from 'react';
import {socket} from '../../contexts/socket';


export const SessionCreate: React.FC<IResourceComponentsProps> = () => {

    const {formProps, saveButtonProps} = useForm<ISession>({

    });

    const save = useCreate<ISession>();
    const [qrCode, setQrCode] = useState();
    const [isSession, setIsSession] = useState(false);
    const { show, close, modalProps } = useModal();
    const createSession = useCreate<any>();
    const getAuthenticate = () => {
        createSession.mutateAsync({
            resource: 'custom/createSessionWhatsApp',
            values: {
                name: formProps.form?.getFieldValue('sessionName')
            }
        }).then( res => {
            console.log(res);
            socket.on('sendQrCode', async (data: any, error: any) => {
                console.log(data);
                setQrCode(data.qrCodeImage);
                show();
            });

            return () => {
                socket.off('sendQrCode');
            };
        })
    }

    useEffect(() => {
        socket.on('createSession', async (data: any, error: any) => {
            console.log(data);
            setIsSession(true);
            close();
        });

        socket.on('updateSession', async (data: any, error: any) => {
            console.log(data);
            setIsSession(true);
            close();
        });

        return () => {
            socket.off('createSession');
            socket.off('updateSession');
        };

    }, [])
    return (
            <Create saveButtonProps={saveButtonProps}>
                <Form
                    {...formProps}
                    layout="vertical"
                >
                    <Row gutter={24}>
                        <Col span={20}>
                            <Form.Item
                                label="Nome Sessione"
                                name="sessionName"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Authenticate"
                                name="authenticate"
                            >
                                <Button type={'primary'}  style={{
                                    width: '100%'
                                }} onClick={getAuthenticate}>Authenticate</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <div hidden={!isSession}>Sessione Autenticata Correttamente!</div>
                        </Col>
                    </Row>
                </Form>
                <Modal {...modalProps} footer={<></>}>
                    <div style={{textAlign: 'center'}}>
                        <Image src={qrCode}></Image>
                    </div>
                </Modal>
            </Create>
    );
};
