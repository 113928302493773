import {IResourceComponentsProps, useCreate, useList} from '@refinedev/core';
import {Create, getValueFromEvent, useForm, useSelect, useTable} from '@refinedev/antd';
import {
    Col,
    Form,
    Input,
    message,
    Row,
    Select,
    Switch,
    Upload,
    UploadProps,
    Image,
    DatePicker,
    Typography,
    Space
} from 'antd';
import {IContact, IGroup, IMessage, ISession} from 'interfaces';
import React, {useEffect, useState} from 'react';
import TextArea from 'antd/es/input/TextArea';
import EmojiPicker, {Emoji, EmojiClickData, EmojiStyle, SuggestionMode, Theme} from 'emoji-picker-react';
import ReactEmojiTextArea from "@nikaera/react-emoji-textarea";
import {API_URL} from '../../constants';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react';
const { Dragger } = Upload;
const {Title} = Typography;
export const MessageCreate: React.FC<IResourceComponentsProps> = () => {

    const [value, setValue] = useState('');

    const [rawValue, setRawValue] = useState('');

    const {formProps, saveButtonProps, onFinish: finish} = useForm<IMessage>({
    });

    const {selectProps} = useSelect<IContact>({
        resource: 'contacts',
        meta: {
            populate: 'group',
        },
        optionLabel: 'name' || 'surname',
        optionValue: 'id'
    });

    const {data: contactList} = useList<IContact>({
        resource: 'contacts',
        meta: {
            populate: 'group',
        },
        pagination: {
            mode: 'server',
            pageSize: 5000
        }
    });

    const {selectProps: sessionSelectProps} = useSelect<ISession>({
        resource: 'sessions',
        optionLabel: 'sessionName',
        optionValue: 'sessionName'
    })

    const {selectProps: groupSelectProps, queryResult} = useSelect<IGroup>({
        resource: 'groups',
        optionLabel: 'description',
        optionValue: 'id'
    })

    const [img, setImg] = useState<any[]>([]);

    const props: UploadProps = {
        name: 'files',
        multiple: true,
        action: API_URL + '/api/upload',
        onChange(info) {
            console.log(formProps.form)
            console.log(info);
            const { status } = info.file;
            if (status !== 'uploading') {
                // formProps.form?.setFieldValue("document", info.file.response);
            }
            if (status === 'done') {
                setImg([...img, ...info.file.response]);
                message.loading(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.loading(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const [selectedEmoji, setSelectedEmoji] = useState<string>("");
    const [dataPianificazione, setDataPianificazione] = useState<string>("");
    function toUnicode(text: string){
        return Array.from(text).map(char => char.codePointAt(0));
    }
    const onClick = (emojiData: any, event: MouseEvent) => {
        console.log(value)
        const emoji = emojiData.native;
        const escapeText = emoji.split("").map((unit: any) => "\\u" + unit.charCodeAt(0).toString(16).padStart(4, "0")).join("");
        setSelectedEmoji(emojiData.native);
        // setValue(value + emoji);
        formProps.form?.setFieldValue('message', formProps.form?.getFieldValue('message') + emoji)
        // console.log(value);
        // setRawValue(rawValue + escapeText);
    }

    const onChange = (e: any) => {
        console.log(e.target.value)
        formProps.form?.setFieldValue('message', e.target.value)
        // setValue(value + e.target.value);
        // setRawValue(value + e.target.value);
    }

    const onChangePianificazione = (e: any) => {
        console.log(e)
    }

    const onFinish = async (values: any) => {
        const emoji = formProps.form?.getFieldValue('message').replace(/\p{Emoji}/ug, (m: any, idx: any) =>
            `${m.split("").map((unit: any) => "\\u" + unit.charCodeAt(0).toString(16).padStart(4, "0")).join("")}`
        )
        console.log(emoji);
        values.message = encodeURIComponent(formProps.form?.getFieldValue('message'));
        let fileImgs = [];
        if (img) {
            for (let i of img) {
                fileImgs.push(i);
            }
        }
        values.image = fileImgs;
        await finish(values);
    }

    useEffect(() => {

    }, [])

    const [totalCount, setTotalCount] = useState<any>(0);
    const getTotalGroup = (e: any) => {
        setTotalCount(contactList?.data.filter((obj: any) => obj.group?.id === e).length);
    }
    return (
        <Create saveButtonProps={saveButtonProps} breadcrumb={false} title={
            <Space>
                <Row gutter={250}>
                    <Col>
                            <Title level={5}>Crea Messaggio</Title>
                    </Col>
                    <Col>
                            <span style={{color: 'green'}}> { (totalCount > 0 ? ' Totale: ' + totalCount + ' Messaggi - Tempo Stimato: ' + parseFloat(((totalCount * 10) / 60).toString()).toFixed(2) + ' minuti' : '')}</span>
                    </Col>
                </Row>

            </Space>}>
                <Form
                    {...formProps}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label="Sessione"
                                name="fromSession"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select {...sessionSelectProps} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Pianifica"
                                name="pianificazione"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <DatePicker style={{ width: '100%'}} showTime={true} onChange={onChangePianificazione}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Destinatario"
                                name="contact"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Select {...selectProps} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={'Gruppo'}
                                name="group"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Select {...groupSelectProps} onSelect={getTotalGroup}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Messaggio"
                                name="message"
                            >
                                    <Input.TextArea size={'large'} rows={8} onInput={onChange} showCount={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="image"
                                label="Immagine"
                                valuePropName="fileList"
                                getValueFromEvent={getValueFromEvent}
                            >
                                <Dragger {...props}
                                         showUploadList={false}
                                         multiple={false}
                                         style={{
                                             width: '100%',
                                             minHeight: 217
                                         }}
                                >
                                    {/*<p className="ant-upload-text">Click or drag file to this area to upload</p>*/}
                                    <p className="ant-upload-hint" style={{fontSize: 12}}>
                                        Upload Immagine
                                    </p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Picker data={data} onEmojiSelect={onClick} />
                        </Col>
                            <Col span={12}>
                                <div style={{
                                    textAlign: 'center'
                                }}>
                                    <Image.PreviewGroup
                                        preview={{
                                            onVisibleChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                        }}
                                    >
                                        {
                                            img && img.length > 0 &&
                                            img.map( (im: any) => {
                                                return <Image style={{borderRadius: 15, width: 380}} src={API_URL + '' + im.url} />
                                            })
                                        }
                                    </Image.PreviewGroup>
                                </div>

                        </Col>
                    </Row>
                </Form>
            </Create>
    );
};
