import React, {useState} from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import {Edit, useForm, useSelect} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Form, Input, Select, Row, Col, Switch } from "antd";

import {IContact, IGroup} from 'interfaces';
export const ContactEdit: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps, queryResult} = useForm<IContact>({
        meta: {populate: ["group"]},
    });

    const {selectProps} = useSelect<IGroup>({
        resource: 'groups',
        optionLabel: 'description',
        optionValue: 'id',
        defaultValue: queryResult?.data?.data.id
    })

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Nome"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Cognome"
                            name="surname"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Telefono"
                            name="cellular"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Gruppo"
                            name={["group", "id"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...selectProps} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
