import React, {useEffect, useState} from 'react';
import { IResourceComponentsProps } from "@refinedev/core";
import {Edit, getValueFromEvent, useForm, useSelect} from '@refinedev/antd';

import {Form, Input, Select, Row, Col, Switch, Image, UploadProps, message, Upload, DatePicker} from 'antd';

import {IContact, IGroup, IMessage, ISession} from 'interfaces';
import TextArea from 'antd/es/input/TextArea';
import {API_URL} from '../../constants';
import dayjs from 'dayjs';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
const { Dragger } = Upload;
export const MessageEdit: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps, queryResult, onFinish: finish} = useForm<IMessage>({
        metaData: {
            populate: 'image, group, contact'
        },
    });


    const {selectProps} = useSelect<IContact>({
        resource: 'contacts',
        optionLabel: 'name' || 'surname',
        optionValue: 'id'
    });

    const {selectProps: sessionSelectProps} = useSelect<ISession>({
        resource: 'sessions',
        optionLabel: 'sessionName',
        optionValue: 'sessionName'
    })

    const {selectProps: groupSelectProps} = useSelect<IGroup>({
        resource: 'groups',
        optionLabel: 'description',
        optionValue: 'id'
    })

    const [img, setImg] = useState<any[]>([]);

    const props: UploadProps = {
        name: 'files',
        multiple: true,
        action: API_URL + '/api/upload',
        onChange(info) {
            console.log(formProps.form)
            console.log(info);
            setImg([]);
            const { status } = info.file;
            if (status !== 'uploading') {
                // formProps.form?.setFieldValue("document", info.file.response);
            }
            if (status === 'done') {
                setImg([...img, ...info.file.response]);
                message.loading(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.loading(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const [selectedEmoji, setSelectedEmoji] = useState<string>("");
    const [value, setValue] = useState('');

    const [rawValue, setRawValue] = useState('');
    function toUnicode(text: string){
        return Array.from(text).map(char => char.codePointAt(0));
    }
    const onClick = (emojiData: any, event: MouseEvent) => {
        console.log(value)
        const emoji = emojiData.native;
        const escapeText = emoji.split("").map((unit: any) => "\\u" + unit.charCodeAt(0).toString(16).padStart(4, "0")).join("");
        setSelectedEmoji(emojiData.native);
        // setValue(value + emoji);
        formProps.form?.setFieldValue('message', formProps.form?.getFieldValue('message') + emoji)
        // console.log(value);
        // setRawValue(rawValue + escapeText);
    }

    const onChange = (e: any) => {
        console.log(e.target.value)
        formProps.form?.setFieldValue('message', e.target.value)
        // setValue(value + e.target.value);
        // setRawValue(value + e.target.value);
    }

    const onFinish = async (values: any) => {
        const emoji = formProps.form?.getFieldValue('message').replace(/\p{Emoji}/ug, (m: any, idx: any) =>
            `${m.split("").map((unit: any) => "\\u" + unit.charCodeAt(0).toString(16).padStart(4, "0")).join("")}`
        )
        console.log(emoji);
        values.message = encodeURIComponent(formProps.form?.getFieldValue('message'));
        let fileImgs = [];
        if (img) {
            for (let i of img) {
                fileImgs.push(i);
            }
        }
        values.image = fileImgs;
        await finish(values);
    }

    const unicodeToChar = function(text: string) {
        return text.replace(/\\u[\dA-F]{4}/gi, function(match) {
            return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
        });
    }

    useEffect(() => {
        if (queryResult?.data && queryResult?.data?.data && queryResult?.data?.data.message) {
            console.log(unicodeToChar(queryResult.data.data.message))
            formProps.form?.setFieldValue('message', decodeURIComponent(queryResult?.data?.data.message));
        }
        if (queryResult?.data && queryResult?.data?.data && queryResult?.data?.data.image) {
            console.log(queryResult?.data?.data.image)
            const imgA = [];
            imgA.push(queryResult?.data?.data.image);
            setImg([...imgA]);
        }
    }, [queryResult?.data?.data.id] );

    const onChangePianificazione = (e: any) => {
        console.log(e)
    }

    return (
        <Edit saveButtonProps={saveButtonProps} breadcrumb={false}>
            { queryResult?.data && queryResult.data.data && queryResult.data.data.id &&
            <Form
                {...formProps}
                layout="vertical"
                onFinish={onFinish}
            >
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            label="Sessione"
                            name="fromSession"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...sessionSelectProps} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Pianifica"
                            name="pianificazione"
                            getValueProps={(value) => ({
                                value: value ? dayjs(value) : '',
                            })}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <DatePicker style={{ width: '100%'}} showTime={true} onChange={onChangePianificazione}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Destinatario"
                            name={['contact', 'id']}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Select {...selectProps} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Gruppo"
                            name={['group', 'id']}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Select {...groupSelectProps} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Messaggio"
                            name="message"
                        >
                                <TextArea size={'large'} style={{height: 250}} onInput={onChange} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="image"
                            label="Immagine"
                            getValueFromEvent={getValueFromEvent}
                        >
                            <Dragger {...props}
                                     showUploadList={false}
                                     multiple={false}
                                     style={{
                                         width: '100%',
                                         minHeight: 250
                                     }}
                            >
                                {/*<p className="ant-upload-text">Click or drag file to this area to upload</p>*/}
                                <p className="ant-upload-hint" style={{fontSize: 12}}>
                                    Upload Immagine
                                </p>
                            </Dragger>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Picker data={data} onEmojiSelect={onClick} />
                    </Col>
                    <Col span={12}>
                        <div style={{
                            textAlign: 'center'
                        }}>
                            <Image.PreviewGroup
                                preview={{
                                    onVisibleChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                }}
                            >
                                {
                                    img && img.length > 0 &&
                                    img.map( (im: any) => {
                                        return <Image style={{borderRadius: 15, width: '80%'}} src={API_URL + '' + im.url} />
                                    })
                                }
                            </Image.PreviewGroup>
                        </div>
                    </Col>
                </Row>
                <hr />
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            label="isProcessing"
                            name="isProcessing"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch  />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="isDone"
                            name="isDone"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch  />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="isSend"
                            name="isSend"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch  />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="isStopped"
                            name="isStopped"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch  />
                        </Form.Item>
                    </Col>
                </Row>
            </Form> }
        </Edit>
    );
};
