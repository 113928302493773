import {
    Refine,
    Authenticated,
} from '@refinedev/core';
import {RefineKbar, RefineKbarProvider} from '@refinedev/kbar';

import {
    AuthPage, ErrorComponent
    , notificationProvider, RefineThemes
    , ThemedLayoutV2
} from '@refinedev/antd';
import '@refinedev/antd/dist/reset.css';

import {DataProvider} from '@refinedev/strapi-v4';
import {BrowserRouter, Route, Routes, Outlet} from 'react-router-dom';
import routerBindings, {NavigateToResource, CatchAllNavigate, UnsavedChangesNotifier} from '@refinedev/react-router-v6';
import {ColorModeContextProvider} from './contexts/color-mode';
import {authProvider, axiosInstance} from './authProvider';
import {API_URL} from './constants';
import {ConfigProvider} from 'antd';
import {DashboardList} from './pages/dashboard';
import React, {useEffect, useState} from 'react';
import {CustomSider} from './components/sider';
import {Header} from './components';
import {ContactCreate, ContactEdit, ContactList} from './pages/contacts';
import {GroupCreate, GroupEdit, GroupList} from './pages/groups';
import {MessageCreate, MessageEdit, MessageList} from './pages/messages';
import {SessionCreate, SessionList} from './pages/sessions';
import {SocketContext, socket} from './contexts/socket';
import {UtilityHelper} from './helper/utility';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
    const utilityHelper = UtilityHelper(API_URL + '/api');
    const [isConnected, setIsConnected] = useState(socket.connected);

    useEffect(() => {
        if (socket.id) {
            utilityHelper.setSocketId(socket.id).then();
        }
        socket.on('connect', () => {
            console.log('Connesso: ', socket.id);
            // setIsConnected(true);
            utilityHelper.setSocketId(socket.id).then();
        });

        socket.on('disconnect', () => {
            console.log('Disconnesso', isConnected)
            setIsConnected(false);
        });

        return () => {
            socket.off('connect');
            socket.off('disconnect');
        };
    }, [])

    return (
        <SocketContext.Provider value={socket}>
            <ToastContainer/>
            <BrowserRouter>
                <RefineKbarProvider>
                    <ColorModeContextProvider>
                        <ConfigProvider theme={RefineThemes.Blue}>
                            <Refine authProvider={authProvider}
                                    dataProvider={DataProvider(API_URL + `/api`, axiosInstance)}
                                    notificationProvider={notificationProvider}
                                    routerProvider={routerBindings}
                                    options={{
                                        syncWithLocation: true,
                                        warnWhenUnsavedChanges: true,

                                    }}
                                    resources={[
                                        {
                                            name: 'dashboard',
                                            list: '/dashboard',
                                            meta: {label: 'Dashboard'},
                                        },
                                        {
                                            name: 'contacts',
                                            list: '/contacts',
                                            create: '/contacts/create',
                                            edit: '/contacts/edit/:id',
                                            meta: {label: 'Contatti'},
                                        },
                                        {
                                            name: 'groups',
                                            meta: {label: 'Gruppi'},
                                            list: '/groups',
                                            edit: '/groups/edit/:id',
                                            create: '/groups/create',
                                            show: '/groups/show/:id',
                                        },
                                        {
                                            name: 'messages',
                                            meta: {label: 'Messaggi'},
                                            list: '/messages',
                                            edit: '/messages/edit/:id',
                                            create: '/messages/create',
                                            show: '/messages/show/:id',
                                        },
                                        {
                                            name: 'sessions',
                                            meta: {label: 'Sessioni'},
                                            list: '/sessions',
                                            create: '/sessions/create',
                                        },
                                    ]}
                            >


                                <Routes>
                                    <Route
                                        element={
                                            <Authenticated
                                                fallback={<CatchAllNavigate to="/login"/>}
                                            >
                                                <ThemedLayoutV2
                                                    Header={() => <Header/>}
                                                    Sider={() => {
                                                        return (<CustomSider/>)
                                                    }}

                                                >
                                                    <Outlet/>
                                                </ThemedLayoutV2>
                                            </Authenticated>
                                        }
                                    >
                                        <Route index element={<NavigateToResource resource="dashboard"/>}/>
                                        <Route path="/dashboard">
                                            <Route index element={<DashboardList/>}/>
                                        </Route>
                                        <Route path="/contacts">
                                            <Route index element={<ContactList/>}/>
                                            <Route path="create" element={<ContactCreate/>}/>
                                            <Route path="edit/:id" element={<ContactEdit/>}/>
                                        </Route>
                                        <Route path="/groups">
                                            <Route index element={<GroupList/>}/>
                                            <Route path="create" element={<GroupCreate/>}/>
                                            <Route path="edit/:id" element={<GroupEdit/>}/>
                                        </Route>
                                        <Route path="/messages">
                                            <Route index element={<MessageList/>}/>
                                            <Route path="create" element={<MessageCreate/>}/>
                                            <Route path="edit/:id" element={<MessageEdit/>}/>
                                        </Route>
                                        <Route path="/sessions">
                                            <Route index element={<SessionList/>}/>
                                            <Route path="create" element={<SessionCreate/>}/>
                                        </Route>
                                    </Route>
                                    <Route
                                        element={
                                            <Authenticated fallback={<Outlet/>}>
                                                <NavigateToResource resource="dashboard"/>
                                            </Authenticated>
                                        }
                                    >
                                        <Route
                                            path="/login"
                                            element={<AuthPage
                                                registerLink={false}
                                                forgotPasswordLink={false}
                                                title={<span style={{ color: 'greenyellow', fontWeight: 600, fontSize: 40}}>WhatZ App Login</span>}
                                                type="login"/>}
                                        />
                                    </Route>
                                    <Route
                                        element={
                                            <Authenticated>
                                                <ThemedLayoutV2>
                                                    <Outlet/>
                                                </ThemedLayoutV2>
                                            </Authenticated>
                                        }
                                    >
                                        <Route path="*" element={<ErrorComponent/>}/>
                                    </Route>
                                </Routes>
                                <RefineKbar/>
                                <UnsavedChangesNotifier/>
                            </Refine>
                        </ConfigProvider>
                    </ColorModeContextProvider>
                </RefineKbarProvider>
            </BrowserRouter>
        </SocketContext.Provider>
    );
};

export default App;
