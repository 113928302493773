import { IResourceComponentsProps, useList } from "@refinedev/core";

import {
    List
} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";


import {Typography} from 'antd';
import React from 'react';
import Anime, { anime } from 'react-anime';
const {Title} = Typography;
export const DashboardList: React.FC<IResourceComponentsProps> = () => {

        return (
            <>
                <Anime easing="easeOutElastic"
                       loop={true}
                       duration={1000}
                       direction="alternate"
                       delay={(el: any, index: number) => index * 240}
                       translateX='13rem'
                       scale={[.75, .9]}>
                    <div className="blue"/>
                    <div className="green"/>
                    <div className="red"/>
                </Anime>
            </>

        );
};
